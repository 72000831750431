import Features from "./components/Features";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import Watch from "./components/Watch";


function App() {
  return (
    <div className="App">
      <div className="header"><Header/></div>
      <div className="home"><Home/></div>
      <div className="features"><Features/></div>
      <div className="watch"><Watch/></div>
      <div className="aboutUs"><Footer/></div>
    </div>
  );
}

export default App;
