import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'

export default function Watch() {
  return (
    <Container >
       <Row >
        <Col>
        <h5>Canlı İzleme</h5>
        <div style={{marginTop:"50px"}}>
            <p className='subtitle'>Tüm cihazlarınızı tek ekrandan takip edebilir, çeşitli <br/> görünüm seçenekleriyle izleme  ekranlarında her zaman <br/> açık şekilde kullanabilirsiniz. Alarmlar çeşitli yöntemlerle<br/>  sistem yöneticisine iletilmekle birlikte anlık olarak izleme <br/> ekranındada gösterilmektedir.</p>
        </div>
        <div style={{marginTop:"50px"}}>
            <Button variant='secondary'>Teklif Alın</Button>
        </div>
        </Col>
       </Row>
    </Container>
  )
}
