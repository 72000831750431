import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { IoIosArrowDown } from "react-icons/io";
import home from "../assest/img/home.svg"
import Slider from "react-slick";
import img from '../assest/img/rowImg.svg';
import img2 from '../assest/img/rowImg2.svg';
import img3 from '../assest/img/rowImg3.svg';
import img4 from '../assest/img/rowImg4.svg';
import img5 from '../assest/img/rowImg5.svg';
export default function Home() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        arrows: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      };
  return (
    <Container id='home'>
        <Row className='mt-5'>
            <Col className="d-flex justify-content-center">
                <h1>Sistem Üstündeki</h1>
                <Button className='custom-button' variant='link'>Gözünüz</Button>
                <div className='mt-2'><IoIosArrowDown  size={40} color='#37E2D5'/></div>
            </Col>
        </Row>
        <Row className='mt-5'>
            <Col className='d-flex justify-content-center text-center'>
            <p className='custom-text'>Sysmote ile sistem odanızı canlı bir şekilde  takip edin ve <br/> entegrasyonlarımız sayesinde  aksiyon alın.</p>
            </Col>
        </Row>
        <Row className='mt-3'>
            <Col className='d-flex justify-content-center'>
            <Button  className="mx-4"  >Özellikler</Button>
            <Button  variant='outline-light' className="mx-4"  >Teklif Alın</Button>
            </Col>
        </Row>
        <Row className='mt-5'>
        <Col className='d-flex justify-content-center'>
      <img src={home} style={{ maxWidth: '100%', height: 'auto' }} />
    </Col>
        </Row>
        <Row className="mt-5">
            <Col>
            <Slider {...settings}>
            <div>
              <img src={img}  alt="First slide"  style={{ filter: "grayscale(1)" }}/>
            </div>
            <div>
              <img src={img2}  alt="Second slide"  style={{ filter: "grayscale(1)" }}/>
            </div>
            <div>
              <img src={img3}  alt="Third slide"  style={{ filter: "grayscale(1)" }}/>
            </div>
            <div>
              <img src={img4}  alt="Fourth slide" style={{ filter: "grayscale(1)" }}/>
            </div>
            <div>
              <img src={img5}  alt="Fifth slide" style={{ filter: "grayscale(1)" }}/>
            </div>
          </Slider>
            </Col>
        </Row>
    </Container>
  )
}
