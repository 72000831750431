import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import logo from '../assest/img/logoFooter.svg'

export default function Footer() {
    return (
        <div id="aboutUs">
            <Container>
                <Row className='py-3 py-md-5'>
                    <Col >
                        <img src={logo} />
                        <p style={{marginTop:"20px"}}>Sistem odanızdaki <br/>
                        detayları kaçırmayın, sizin <br/>
                        için izlesin ve otomatize <br/>
                        etsin.</p>
                    </Col>
                    <Col className='d-flex justify-content-end' >
                        <div className="text-right">
                            <h3><strong>İletişim</strong></h3>
                            <div className='mt-4'>
                                <h6>Adres:</h6>
                                <ul>
                                    <li>Gaziosmanpaşa Mah.</li>
                                    <li>Tahran Cad. No:30</li>
                                    <li>Ofis 1010 Çankaya/Ankara</li>
                                </ul>
                            </div>
                            <div className='mt-3'>
                                <h6>Telefon:</h6>
                                <p>0312 400 00 61</p>
                            </div>
                            <div className='mt-3'>
                                <h6>E-Posta:</h6>
                                <p>info@priente.com</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='mb-2 d-flex justify-content-center'>
                    <Col xs={6} md={6} lg={8} className='d-flex justify-content-center'>
                        <p className='mb-0'>© 2024 Priente Software. All Rights Reserved.</p>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}
