import React from 'react'
import { Card,  Col, Container, Row } from 'react-bootstrap'
import icon1 from '../assest/img/icon1.svg'
import icon2 from '../assest/img/icon2.svg'
import icon3 from '../assest/img/icon3.svg'
import icon4 from '../assest/img/icon4.svg'
export default function Features() {
  return (
    <Container id='features'>
        <Row>
            <Col className='d-flex justify-content-center'>
                <h5>Cihaz Tipleri</h5>
               
            </Col>
        </Row>
        <Row>
            <Col className='d-flex justify-content-center'>
                
                <p className='subtitle'>Sysmote ile sistem odanızdaki cihazları ve olayları izleyin, anlık aksiyonlar alın.</p>
            </Col>
        </Row>
        <Row className='mt-5'>
    <Col>
        <Card className="h-100">
            <Card.Body>
                <img src={icon4} />
                <div className='mt-5 mb-3'>
                    <h4>PDU Cihazları</h4>
                </div>
                <p className='subtitle'>Akıllı PDU cihazlarını yönetebilir, kullanım parametrelerini takip edebilir ve uzaktan aksiyon alabilirsiniz.</p>
            </Card.Body>
        </Card>
    </Col>
    <Col>
        <Card className="h-100">
            <Card.Body>
                <img src={icon1} />
                <div className='mt-5 mb-3'>
                    <h4>Ortam İzleme Cihazları</h4>
                </div>
                <p className='subtitle'>Sıcaklık, Nem, Hava Kalite, Kapı Durumu gibi takip etmek istediğiniz verilerle ilgili canlı grafikler görebilir, oluşturduğunuz profillere göre alarmlar alabilirsiniz.</p>
            </Card.Body>
        </Card>
    </Col>
    <Col>
        <Card className="h-100">
            <Card.Body>
                <img src={icon2} />
                <div className='mt-5 mb-3'>
                    <h4>RFID Cihazlar</h4>
                </div>
                <p className='subtitle'>Sistem odanızdaki kabinlere erişim yönetimi yapabilir, belirli yetkiler çerçevesinde giriş çıkış takibi yapabilirsiniz.</p>
            </Card.Body>
        </Card>
    </Col>
    <Col>
        <Card className="h-100">
            <Card.Body>
                <img src={icon3} />
                <div className='mt-5 mb-3'>
                    <h4>Kamera ve Klimalar</h4>
                </div>
                <p className='subtitle'>IP Kameralarınız ile entegre olarak alarm durumlarında fotoğraflama yapabilir. Bu fotoğrafları alarmlara ekler ve kurum hafızası oluşturur. Klimalarınızda sisteme eklenerek canlı olarak takibi yapılabilir.</p>
            </Card.Body>
        </Card>
    </Col>
</Row>

    </Container>
  )
}
